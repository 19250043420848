<template>
  <section>

    <v-card class="my-4 rounded-lg border" elevation="0">
      <v-card-title class="d-flex justify-space-between align-center">
        <div>
          <span class="blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12">{{ $t("Question") }}</span>
        </div>
        <div>
          <span class="f13 font-weight-light"> {{ question.createDate | moment("from", "now") }} </span>
        </div>
      </v-card-title>

      <v-card-text>
        <p class="f14 text-justify" v-html="question.question"></p>
      </v-card-text>
    </v-card>

    <v-card class="my-4 mx-4 rounded-lg border" elevation="0" v-for="(answer, index) in question.answers" :key="index">
      <v-card-title class="d-flex justify-space-between align-center" elevation="0">
        <div>
          <span class="green lighten-5 green--text text--darken-1 p-2 rounded-lg f12">{{ $t("Answer") }} {{index+1}}</span>
        </div>
        <div>
          <span class="f13 font-weight-light">{{ answer.createDate | moment("from", "now") }}</span>
        </div>

      </v-card-title>

      <v-card-text>
        <p class="f14" v-html="answer.answer"></p>
      </v-card-text>

      <v-divider dark class="m-0"></v-divider>

      <v-card-actions class="d-flex justify-space-between align-items-center">
        <div class="pointer text-decoration-none" @click.prevent="openDrBottomSheet(answer.doctorId)">
          <v-avatar size="36">
            <v-img :lazy-src="require('@/assets/images/profile-circle.png')" :src="answer.doctorProfilePic"></v-img>
          </v-avatar>
          <span class="ms-2 font-weight-bold f13">{{ answer.doctorFullName }}</span>
        </div>

        <v-btn v-if="answer.liked" flat depressed small class="h-auto py-1" color="primary" @click="setUnlike(answer, index)">
          <div class="d-flex d-inline-flex align-items-center">
            <iconly type="bold" name="heart" class="ms-1 me-0 wh-20" />
            <span v-if="answer.userLikeAnswers && answer.userLikeAnswers.length" class="me-1 text-white f12">
              {{
                  answer.userLikeAnswers.length
              }}
            </span>
            <span v-else class="me-1 text-white f12">0</span>
          </div>
        </v-btn>

        <v-btn v-else flat outlined small class="h-auto py-1 border border-primary" @click="setLike(answer, index)">
          <div class="d-flex d-inline-flex align-items-center primary--text">
            <iconly type="bold" name="heart" class="ms-1 me-0 wh-20"></iconly>
            <span v-if="answer.userLikeAnswers && answer.userLikeAnswers.length" class="me-1 f12">
              {{
                  answer.userLikeAnswers.length
              }}
            </span>
            <span v-else class="me-1 f12">0</span>
          </div>
        </v-btn>

      </v-card-actions>
    </v-card>

    <v-footer app fixed elevation="0" class="mb-2" color="transparent">
      <v-container fluid class="d-flex flex-column align-items-end">
        <v-btn color="primary" rounded to="/c/questions/add" class="mb-3"> 
          {{ $t("Add question") }} +
        </v-btn>

        <v-card rounded="lg" class="w-100 d-flex flew-row justify-space-between align-items-center px-2 py-1 border" elevation="1">
          <span class="light-blue lighten-5 blue--text f14 my-1 rounded px-4 py-1">
            {{question.answers.length}} {{ $t("Answer") }}
          </span>

          <div class="d-flex align-items-center">
            <v-card class="mx-1 px-3 py-1 d-flex align-items-center border" flat>
              <span class="me-2 ms-0 f14 grey--text text--darken-2">{{question.userViews.length}}</span>
              <iconly type="light" name="show" color="#20B1EA" />
            </v-card>
            
            <v-card v-if="question.liked" class="mx-1 px-3 py-1 d-flex align-items-center border" color="primary" flat @click="setUnlikeQuestion(question)">
              <span class="me-2 ms-0 f14 white--text">{{question.userLikeQuestions.length}} </span>
              <iconly type="bold" name="heart" color="#FFFFFF" />
            </v-card>
            <v-card v-else class="mx-1 px-3 py-1 d-flex align-items-center border" flat @click="setLikeQuestion(question)">
              <span class="me-2 ms-0 f14 grey--text text--darken-2">{{question.userLikeQuestions.length}} </span>
              <iconly type="light" name="heart" color="#20B1EA"/>
            </v-card>
          </div>
        </v-card>
      </v-container>
    </v-footer>
    
    <BshDoctor ref="drBottomSheet" />
  </section>
</template>
<script>
import BshDoctor from "@/components/DoctorProfile.vue";
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();

export default {
  components: {
     BshDoctor
  },
  data() {
    return {
      questionId: null,
      question: {
        answers: [],
        userLikeQuestions: [],
        userViews: []
      },
    };
  },
  methods: {
    openDrBottomSheet(id){
      this.$refs.drBottomSheet.openWithDoctorId(id);
    },
    setViewQuestion(id) {
      apiService.setViewQuestion(id, 'CLIENT');
    },
    getQuestionDetails(id) {
      apiService.getQuestionDetails(id)
        .then((response) => {
          this.question = response.data;
          
          if(this.question.clientUserId  == this.$store.getters.clientId){
            var menu = [
              // {
              //   title: "Edit",
              //   to: "/c/questions/" + this.question.id + "/edit",
              //   click: '',
              // }, 
              {
                title: "Delete",
                to: '',
                click: this.deleteQuestion,
              },
            ];
            this.$store.commit('update_threeDotsActive', true);
            this.$store.commit('update_threeDotsMenu', menu);
            this.$store.commit('update_notificationActive', false);
          }
          
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
            });

        });
    },
    setUnlike(answer, index) {
      apiService.unlikeAnswer(answer.id, "CLIENT")
      .then(() => {
          this.question.answers[index].liked = false;
          this.question.answers[index].userLikeAnswers.pop();
      });
    },
    setLike(answer, index) {
      apiService.likeAnswer(answer.id, "CLIENT")
      .then(() => {
          this.question.answers[index].liked = true;
          this.question.answers[index].userLikeAnswers.push({});
      });
    },
    
    setUnlikeQuestion(question) {
      apiService.unlikeQuestion(question.id, "CLIENT")
      .then(() => {
          this.question.liked = false;
          this.question.userLikeQuestions.pop();
      });
    },
    setLikeQuestion(question) {
      apiService.likeQuestion(question.id, "CLIENT")
      .then(() => {
          this.question.liked = true;
          this.question.userLikeQuestions.push({});
      });
    },
    deleteQuestion(){
      this.$swal({
        icon: "warning",
        text: this.$t('Are you sure you want to delete this question?'),
        confirmButtonText: this.$t('Yes'),
        showDenyButton: true,
        denyButtonText: this.$t('No'),
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.submitDeleteQuestion();
          }
        });
    },
    submitDeleteQuestion() {
      apiService.deleteQuestion(this.question.id)
        .then(() => {
          this.$swal({
            icon: "success",
            text: this.$t('Your answer was delete successfully'),
            confirmButtonText: this.$t('OK'),
          })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
            });
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          })
        });
    },
  },
  mounted() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
    this.questionId = this.$route.params.questionId;
    this.getQuestionDetails(this.questionId);
    this.setViewQuestion(this.questionId);
  },
};
</script>